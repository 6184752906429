import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2870615501/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "modal-forms"
    }}>{`Modal Forms`}</h1>
    <p>{`There are places throughout your bundle and packages where you will want to present a Form, as defined
in the platform using the Form Builder, in order to collect feedback from users. This may be an actual
feedback form, or a form that gathers information required to start a workflow process. In any of these
situations the process is the same: define a form using the Form Builder in the Space Console and then
open the modal form using the `}<inlineCode parentName="p">{`openModalForm`}</inlineCode>{` function.`}</p>
    <p>{`More information on the underlying capabilities of rendering forms is detailed in the documentation for
the `}<inlineCode parentName="p">{`CoreForm`}</inlineCode>{` component.`}</p>
    <h2 {...{
      "id": "modal-form-container"
    }}>{`Modal Form Container`}</h2>
    <p>{`In order to use the Modal Form functionality you will need to ensure that the `}<inlineCode parentName="p">{`ModalFormContainer`}</inlineCode>{` is
rendered at the root of the `}<strong parentName="p">{`package`}</strong>{` you are using it in. Note that it is rendered per-package and
not in the bundle app itself.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { ModalFormContainer } from '@kineticdata/bundle-common';

const App = () => (
  <>
    <ModalFormContainer />
  </>
);
`}</code></pre>
    <h2 {...{
      "id": "opening-a-modal-form"
    }}>{`Opening a Modal Form`}</h2>
    <pre><code parentName="pre" {...{}}>{`openModalForm(options)
* options -> object
  * submissionId -> string = id of the submission to be rendered (required if formSlug not provided).
  * formSlug -> string = slug for the form to be rendered (required if submissionId not provided).
  * kappSlug -> string = required if the form being rendered is a kapp form, otherwise assumes it's a datastore form.
  * values -> object = a key value object to pre-populate the initial values of a form.
  * title -> string = the title of the modal.
  * confirmationMessage -> string = message displayed once the form submission has completed.
  * duration -> number = number of milliseconds that the modal should stay open after the form is completed (stays open indefinitely if not provided)
`}</code></pre>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { openModalForm } from '@kineticdata/bundle-common';

openModalForm({
  formSlug: 'feedback',
  kappSlug: 'services',
  values: {
    'Feedback Location': 'Homepage',
  },
  title: 'Homepage Feedback',
  confirmationMessage: 'Thank you for telling us how you feel.'
});
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      